import React from "react";
import Placard from "./Placard";

export default function YouTubePiece({
  videoId,
  title,
  subtitle,
  children,
  flip = false,
}) {
  return (
    <div className={"piece " + (flip ? "flip" : "")}>
      <div className="frame-wrapper">
        <div className="frame">
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <Placard title={title} subtitle={subtitle} children={children} />
    </div>
  );
}
