import Footer from "./Footer";
import Header from "./Header";
import Mimesis from "./pages/Mimesis";
import Index from "./pages/Index";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Header className="hero-index"></Header>
          <Index />
        </Route>
        <Route path="/mimesis" exact>
          <ScrollToTop />
          <Header
            className="hero-mimesis"
            text="Mimesis"
            phase="Exhibit Phase: Early Concept Development"
          ></Header>
          <Mimesis></Mimesis>
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;
