export default function Footer() {
  return (
    <div className="footer">
      <ul className="social-list">
        <li style={{ marginBottom: 30 }}>Stay connected:</li>
        <li>
          <a href="https://twitter.com/getafeelforit">Twitter</a>
        </li>
        <li>
          <a href="https://instagram.com/getafeelfor.it">Instagram</a>
        </li>
      </ul>
    </div>
  );
}
