import { Link } from "react-router-dom";

export default function Index() {
  return (
    <div>
      <ExhibitionLink to="/mimesis" title="Mimesis">
        Exploring the ways in which we become automatons that conform, copy,
        desire, and become violent.
      </ExhibitionLink>
    </div>
  );
}

function ExhibitionLink({ to, title, children }) {
  return (
    <div>
      <Link to={to} className="exhibition-link">
        <div className="title">{title} ⟶</div>
        {children}
      </Link>
    </div>
  );
}
