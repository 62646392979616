import { Link } from "react-router-dom";

export default function Header({ className = "", light, text, phase }) {
  return (
    <div className={"hero light " + className}>
      <div className="logo">
        <h1 className="light">
          <Link to="/">
            <span>get</span>
            <span className="alt">a</span>
            <span>feel</span>
            <span className="alt">for</span>
            <span>.it</span>
          </Link>
        </h1>
        <h3 className="subheader">
          digital exhibits for
          <br />
          uncommon ideas
        </h3>
      </div>

      {text ? (
        <div className="hero-text">
          <h2>Mimesis</h2>
        </div>
      ) : null}
      {phase ? <span className="phase">{phase}</span> : null}
    </div>
  );
}
