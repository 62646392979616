export default function Placard({ title, subtitle, children }) {
  return (
    <>
      <div className="placard">
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
        <div className="description">{children}</div>
      </div>
    </>
  );
}
