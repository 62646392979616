import Placard from "../Placard";
import YouTubePiece from "../YouTubePiece";

export default function Mimesis() {
  return (
    <div className="exhibit-wrapper">
      <h3 className="exhibit-header">
        Exploring the ways in which we become <strong>automatons</strong> that
        conform, copy, desire, and become violent.
      </h3>
      <YouTubePiece
        videoId="TYIh4MkcfJA"
        title="The Asch Conformity Experiment"
        subtitle="YouTube"
      >
        <>
          <p>
            Would you continue to give what you <em>knew</em> to be a correct
            answer, even while everyone around you kept agreeing to the wrong
            answer?
          </p>
          <p>Would you really though?</p>
          <p>
            The psychological pain of disagreeing with others, can lead us to
            act against our deepest held convictions.
          </p>
          <p>Mimicry prevails.</p>
        </>
      </YouTubePiece>
      <YouTubePiece
        videoId="7tKZB2k14iY"
        title="The Rival"
        subtitle="YouTube, NatGeoTV"
        flip={true}
      >
        <>
          <p>
            Both toddlers and dogs don't mind a lack of attention... until the
            attention is targeted at a &ldquo;rival&rdquo; object.
          </p>
          <p>Tantrums prevail.</p>
        </>
      </YouTubePiece>
      <h3 className="exhibit-header">
        &ldquo;I don't know &mdash; that's just how things are done around
        here.&rdquo;
      </h3>
      <YouTubePiece
        videoId="X6kWygqR0L8"
        title="Synchronicity in The Waiting Room"
        subtitle="YouTube, Brain Games"
      >
        <p>The absurd ritual.</p>
        <p>The mimicry of the absurd ritual with the group.</p>
        <p>The continuation of the absurd ritual without the group.</p>
        <p>The transmission of the absurd ritual to the new group.</p>
      </YouTubePiece>
      <div className="piece">
        <div className="frame-wrapper">
          <div className="frame">
            <a href="https://i.snipboard.io/kdu77.jpg">
              <img
                src="https://i.snipboard.io/kdu77.jpg"
                style={{ width: "100%" }}
              />
            </a>
          </div>
        </div>
      </div>
      <YouTubePiece
        videoId="DigiWS1YhxI"
        title="Black Friday Frenzies"
        subtitle="Oaks Mall in California, November 25, 2011"
      >
        Could the Urban Outfitter's deals really have been that good?
      </YouTubePiece>
      <div className="piece">
        <div className="frame-wrapper">
          <div className="frame">
            <img
              src="https://s1.ibtimes.com/sites/www.ibtimes.com/files/styles/md/public/2014/11/28/bf-fights-1.jpg"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <Placard
          title="Mimesis and Violence"
          subtitle={
            <span>
              Reuters,{" "}
              <a href="https://www.ibtimes.com/black-friday-fights-2014-shoppers-scramble-holiday-deals-stores-us-uk-video-1730637">
                IBT
              </a>
            </span>
          }
        >
          <p>
            Shoppers wrestle over a television on Black Friday at an Asda
            superstore in Wembley, north London, Nov. 28, 2014.
          </p>
          <p>
            &ldquo;Violence is mimetic rivalry itself becoming violent as the
            antagonists who desire the same object keep thwarting each other and
            desiring the object all the more.&rdquo; &mdash;{" "}
            <em>Mimesis and Violence, The Girard Reader</em>
          </p>
        </Placard>
      </div>
    </div>
  );
}
